/* You can add global styles to this file, and also import other style files */
/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';

html {
    position: fixed;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    width: 100%;

    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Noto Sans', Ubuntu, Cantarell,
        'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    --font-family-monospace: 'Menlo', 'DejaVu Sans Mono', 'Liberation Mono', 'Consolas', 'Ubuntu Mono', 'Courier New',
        'andale mono', 'lucida console', monospace !important;
}

body {
    margin: 0;
    padding: 0;
    /* background: #cccccc !important; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-flex-basis: auto; /* Safari 6.1+ */
    flex-basis: auto;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
}

/*Gloabls*/
//
//::-webkit-scrollbar {
//    width: 12px;
//    height: 12px;
//    /* height: 0px; */
//    background-color: rgba(0, 0, 0, 0);
//}
//
//::-webkit-scrollbar-track {
//    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
//    border-radius: 10px;
//}
//
//::-webkit-scrollbar-thumb {
//    /* background-color: darkgrey; */
//    /* outline: 1px solid slategrey; */
//    /* border: 2px solid transparent; */
//    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
//    border-radius: 10px;
//}

.clear {
    background-color: transparent !important;
}

.text-secondary {
    color: rgba(0, 0, 0, 0.7);
}

table {
    border-collapse: separate;
}
/* td { border: solid 1px #000; } */
tr:first-child th:first-child {
    border-top-left-radius: 10px;
}
tr:first-child th:last-child {
    border-top-right-radius: 10px;
}
/* tr:last-child td:first-child { border-bottom-left-radius: 10px; }
tr:last-child td:last-child { border-bottom-right-radius: 10px; } */

/* end globals */
/* article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */

/* @media (max-width: 768px) {
    .xs-hide {
        display: none;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
} */

/* .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    overflow-y: scroll;
} */

/*end----  */

.spacer {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.content {
    height: 200px;
}

.vertical {
    height: 600px;
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
}

.center {
    padding: 70px 0;
    border: 3px solid green;
}

.pac-container {
    /*for google placename response */
    width: 330px !important;
    margin-left: -10px;
    margin-top: 5px;
}

.mat-sidenav-content {
    /*added for issue with align end md-navbar */
    overflow: hidden !important;
    /* background: #f0f0f0 !important; */
    /* background: #cccccc !important; */
    color: #504e4b !important;
    background: rgb(245, 245, 248);
}

.ol-zoom {
    left: auto !important;
    right: 8px !important;
    top: 20px !important;
}

.ol-zoom button {
    width: 35px !important;
    height: 35px !important;
    font-size: 20px !important;
    background: #363636 !important;
    color: #f0f0f0 !important;
    cursor: pointer;
}

/*main map default config*/

.layer-item .mat-slide-toggle-label {
    line-height: 50px !important;
}

mat-dialog-container {
    position: relative;
}

.splash {
    position: absolute;
    z-index: 2000;
    background: white;
    color: gray;
    width: 100vw;
    height: 100vh;
}

.splash-title {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.splash-title h1 {
    font-size: 26px;
}

.splash-progress-color {
    border-radius: 4px 4px 0 0;
}

.data-table-empty-message {
    display: inline-block;
    padding: 15px;
    margin: auto;
}

.mat-drawer-content {
    overflow: visible !important;
}

mat-drawer-content {
    overflow: visible !important;
}

.ct-series-a .ct-slice-donut {
    stroke: darkred !important;
}

.ct-series-b .ct-slice-donut {
    stroke: orangered !important;
}

.ct-series-c .ct-slice-donut {
    stroke: green !important;
}

/* added material 7.2.2 to hide scrolls side navs */
.job-list-sidenav .mat-drawer-inner-container {
    overflow: hidden !important;
}

.toast-container.toast-top-full-width .toast,
.toast-container.toast-bottom-full-width .toast {
    width: 100% !important;
    padding-left: 115px;
}

.toast-info {
    /* background: #0069c0 !important; */
    background-color: #1c1c1c !important;
}

/* flex-basis: auto; */

/* .cdk-overlay-container .cdk-overlay-pane{
    background-color: white;
 } */

.ng-trigger-transformPanel {
    background-color: white;
}

.min-width-100 {
    min-width: 100px;
}

.min-width-150 {
    min-width: 150px;
}

.min-width-200 {
    min-width: 200px;
}

.pvs-page-card-header .action button {
    margin-right: 1rem;
}

@media screen and (max-width: 768px) {
    .pvs-page {
        padding: 0.7rem !important;
    }
    .pvs-page nb-card {
        margin-bottom: 0.7rem !important;
    }

    .pvs-page .tab-link {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

// hide arrows from input number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.field-error-message {
    color: #de0000;
}

// important: fix bug nb-select on dialog ↓
html {
    top: 0 !important;
}
.cdk-global-scrollblock {
    overflow-y: hidden;
}
// important: fix bug nb-select on dialog ↑

.pvs-filter {
    display: inline-flex;
    margin-right: 3rem;
    margin-bottom: 0.5rem;
    padding-bottom: 3px;
    border-bottom: solid 1px #adadad;
    span:not(.text) {
        padding-right: 0.5rem;
        padding-top: 10px;
    }
    input {
        //width: inherit !important;
    }
    nb-radio {
        display: inline-block;
        label {
            padding: 0;
        }
    }
}
@media all and (max-width: 575px) {
    .pvs-filter {
        width: 100%;
        padding-bottom: 0;
        border-bottom: none;
        min-width: 0 !important;
        margin-right: 0;
    }
}

.min-width-100 {
    min-width: 100px;
}

.min-width-200 {
    min-width: 200px;
}

.min-width-250 {
    min-width: 250px;
}

.min-width-300 {
    min-width: 300px;
}

.min-width-400 {
    min-width: 400px;
}
